<template>
  <span class="product-text">
    <span :class="['product-narrative', narrativeTagLineClass]">
      <span v-if="extraSavings" class="product-special" v-html="extraSavings" />
      {{ product.narrative_tag_line }}
      <span v-if="pointsText" class="critic-score"
        >{{ pointsText }} Points</span
      >
    </span>
  </span>
</template>

<script>
export default {
  name: 'NarrativeProductCardTagLine',
  props: {
    product: {
      type: Object,
      required: true,
    },
    extraSavings: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    narrativeTagLineClass() {
      if (this.product.narrative_tag_line.length <= 30) {
        return 'narrative-s'
      } else if (this.product.narrative_tag_line.length <= 40) {
        return 'narrative-m'
      } else if (this.product.narrative_tag_line.length <= 60) {
        return 'narrative-l'
      } else {
        return 'narrative-xl'
      }
    },
    pointsText() {
      if (!this.product.expert_reviews) return null
      // Get the highest review, look for + if there is a tie for highest
      const review = this.product.expert_reviews.reduce(
        (prev, current) =>
          prev.points === current.points && prev.show_plus
            ? prev
            : prev.points > current.points
            ? prev
            : current,
        {}
      )
      if (!review.points) return null

      let pointsStr = `${review.points}`
      if (review.min_points) {
        pointsStr = `${review.min_points}-${pointsStr}`
      }
      if (review.show_plus) {
        pointsStr += '+'
      }
      return pointsStr
    },
  },
}
</script>
